import { List } from "@mantine/core";

export const TextPageListItemHeader = ({ children }: { children: string }) => (
  <List.Item
    mt={"sm"}
    style={{
      fontSize: 25,
      fontFamily: "GothamUltra",
    }}
  >
    {children}
  </List.Item>
);

export default TextPageListItemHeader;
