import { Image, Stack } from "@mantine/core";
import DownloadAppButton from "./DownloadAppButton";
import { useMediaQuery } from "@mantine/hooks";

export const HomePage = () => {
  const largeScreen = useMediaQuery("(min-width: 56.25em)");

  return (
    <Stack align={"center"} justify={"center"} h={"75vh"} gap={"xl"}>
      <Image src="logoText.png" w={largeScreen ? "50%" : "80%"} />
      <DownloadAppButton />
    </Stack>
  );
};

export default HomePage;
