import { Box, List, Stack, Text } from "@mantine/core";
import TextPageListItemHeader from "../../components/text_page/TextPageListItemHeader";
import TextPageWrapper from "../../components/text_page/TextPageWrapper";

export const StandardsPage = () => {
  return (
    <TextPageWrapper
      title={"Puzzlit Community Standards"}
      lastUpdated={"June 24, 2024"}
    >
      <Stack>
        <Text>
          Welcome to Puzzlit! Our goal is to create a fun, engaging, and safe
          community where users can enjoy solving puzzles and sharing their
          achievements with friends. To maintain a positive environment, we ask
          all members to adhere to the following community standards.
        </Text>
        <List type={"ordered"}>
          <TextPageListItemHeader>Respectful Behavior</TextPageListItemHeader>
          <List type={"unordered"}>
            <List.Item>
              Respect Others: Treat fellow users with kindness and respect.
              Avoid derogatory, discriminatory, or hateful comments.
            </List.Item>
            <List.Item>
              No Harassment or Bullying: Harassment, threats, and bullying are
              strictly prohibited. This includes any form of intimidation or
              abuse.
            </List.Item>
          </List>

          <TextPageListItemHeader>Appropriate Content</TextPageListItemHeader>
          <List type={"unordered"}>
            <List.Item>
              Family-Friendly: Puzzlit is a platform for all ages. Content that
              is sexually explicit, violent, or otherwise inappropriate for a
              general audience is not allowed.
            </List.Item>
            <List.Item>
              No Hate Speech: Content that promotes racism, bigotry, hatred, or
              violence against individuals or groups based on race, ethnicity,
              religion, disability, gender, age, or sexual orientation is
              prohibited.
            </List.Item>
            <List.Item>
              No Spam or Scams: Avoid posting repetitive, irrelevant, or
              promotional content. Do not engage in scams or fraudulent
              activities.
            </List.Item>
          </List>

          <TextPageListItemHeader>Privacy and Security</TextPageListItemHeader>
          <List type={"unordered"}>
            <List.Item>
              Personal Information: Do not share personal information such as
              phone numbers, addresses, or financial information publicly.
              Respect the privacy of others.
            </List.Item>
            <List.Item>
              Private Accounts: Users can make their accounts private and must
              accept follow requests. Do not attempt to bypass this feature or
              harass users for access.
            </List.Item>
          </List>

          <TextPageListItemHeader>Intellectual Property</TextPageListItemHeader>
          <List type={"unordered"}>
            <List.Item>
              Original Content: Only share content you own or have permission to
              use. Respect copyrights, trademarks, and other intellectual
              property rights.
            </List.Item>
            <List.Item>
              Credit Creators: If you share content created by others, give
              proper credit.
            </List.Item>
          </List>

          <TextPageListItemHeader>
            Interactions and Engagement
          </TextPageListItemHeader>
          <List type={"unordered"}>
            <List.Item>
              Positive Engagement: Like and comment on posts in a positive and
              constructive manner. Negative or trolling comments are not
              tolerated.
            </List.Item>
            <List.Item>
              Report Violations: If you encounter content or behavior that
              violates these standards, use the reporting feature to notify our
              moderation team.
            </List.Item>
          </List>

          <TextPageListItemHeader>Accountability</TextPageListItemHeader>
          <List type={"unordered"}>
            <List.Item>
              Follow the Rules: Adherence to these standards is mandatory.
              Violation of any community standard can result in actions ranging
              from warnings to account suspension or termination.
            </List.Item>
            <List.Item>
              Appeal Process: Users have the right to appeal moderation
              decisions. Appeals must be submitted within 14 days of the action.
            </List.Item>
          </List>

          <TextPageListItemHeader>Updates to Standards</TextPageListItemHeader>
          <List type={"unordered"}>
            <List.Item>
              Continuous Improvement: These standards may be updated
              periodically. Users will be notified of significant changes and
              are expected to comply with the latest version.
            </List.Item>
          </List>
        </List>
        <Box>
          <Text>
            By following these community standards, we can ensure that Puzzlit
            remains a fun, respectful, and safe place for everyone. Thank you
            for being a part of our community!
          </Text>
          <br />
          <Text>Puzzlit LLC | admin@puzzlitapp.com</Text>
        </Box>
      </Stack>
    </TextPageWrapper>
  );
};

export default StandardsPage;
