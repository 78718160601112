import { Title } from "@mantine/core";

export const TextPageListItemSubHeader = ({
  children,
}: {
  children: string;
}) => (
  <Title order={5} mt={"sm"}>
    {children}
  </Title>
);

export default TextPageListItemSubHeader;
