import { Box, List, Stack, Text } from "@mantine/core";
import TextPageWrapper from "../../components/text_page/TextPageWrapper";
import TextPageListItemHeader from "../../components/text_page/TextPageListItemHeader";
import TextPageListItemSubHeader from "../../components/text_page/TextPageListItemSubHeader";

export const PrivacyPolicyPage = () => (
  <TextPageWrapper
    title={"Privacy Policy for Puzzlit LLC"}
    lastUpdated={"June 24, 2024"}
  >
    <Stack>
      <Text>
        Welcome to Puzzlit, the social media puzzle app that brings joy to your
        day! At Puzzlit, we are dedicated to protecting your privacy and
        ensuring the security of your data. This Privacy Policy is designed to
        provide you with clear information about what data we collect, how we
        use it, when we share it, and the controls you have over your
        information while using our services. Please take the time to carefully
        read this Privacy Policy to understand how Puzzlit respects and handles
        your data. By using our services, you agree to the terms outlined in
        this Privacy Policy.
      </Text>
      <List type={"ordered"}>
        <TextPageListItemHeader>Information We Collect</TextPageListItemHeader>
        <Text>
          We collect different types of information to provide you with a
          seamless Puzzlit experience. This information can be categorized as
          follows:
        </Text>
        <TextPageListItemSubHeader>
          1.1 Data You Provide to Us
        </TextPageListItemSubHeader>
        <List type={"unordered"}>
          <List.Item>
            Account Creation Data: To create an account, we require specific
            information, including your username, phone number, and a password.
          </List.Item>
          <List.Item>
            Profile Information: You may choose to enhance your Puzzlit
            experience by providing additional information such as a display
            name and profile photo.
          </List.Item>
        </List>
        <TextPageListItemSubHeader>
          1.2 Data We Collect When You Use Our Services
        </TextPageListItemSubHeader>
        <List type={"unordered"}>
          <List.Item>
            Content Data: Puzzlit is all about puzzles and challenges. We
            collect data related to the puzzles you solve, your progress, and
            any interactions within the app. This does not include photos or
            videos.
          </List.Item>
          <List.Item>
            Usage Data: We gather information about your interactions with our
            app, such as the puzzles you solve, your puzzle preferences, and the
            time spent on various puzzles.
          </List.Item>
        </List>
        <TextPageListItemHeader>
          How We Use Your Information
        </TextPageListItemHeader>
        <Text>
          We value your privacy and utilize your data carefully and thoughtfully
          to provide you with a better Puzzlit experience. Here are the main
          purposes for which we use your information:
        </Text>
        <List type={"unordered"}>
          <List.Item>
            Operation and Improvement of Services: We use your data to create
            and manage your account, display puzzles, and enhance your
            experience within the app.
          </List.Item>
          <List.Item>
            Communication: We may contact you for app-related communications,
            such as important updates, announcements, or responding to your
            inquiries.
          </List.Item>
          <List.Item>
            Research and Analytics: We may use data for research, analysis, and
            statistical purposes to improve and refine our services.
          </List.Item>
          <List.Item>
            Security: We employ your data to ensure the security and integrity
            of Puzzlit, as well as to comply with relevant laws and regulations.
          </List.Item>
        </List>
        <TextPageListItemHeader>
          When We Share Your Information
        </TextPageListItemHeader>
        <Text>
          Your data is shared only when it is necessary to provide you with
          Puzzlit services or as required by law. Here are the circumstances
          under which we share your information:
        </Text>
        <List type={"unordered"}>
          <List.Item>
            Service Providers: We work with trusted service providers to deliver
            and enhance our services. These providers may have access to your
            data to assist in technical support, analytics, or other
            service-related tasks.
          </List.Item>
          <List.Item>
            Legal and Statutory Purposes: We may share data when required by
            law, such as in response to legal requests or to address issues like
            fraud or security concerns.
          </List.Item>
          <List.Item>
            Change in Ownership: In the event of a merger, acquisition, or sale
            of assets, your information may be shared, sold, or transferred to
            new ownership. This Privacy Policy will apply to any transferred
            data.
          </List.Item>
        </List>
        <TextPageListItemHeader>Data Retention</TextPageListItemHeader>
        <Text>
          Our goal is to keep your information for only as long as it is
          necessary to provide Puzzlit services. Here is an overview of our data
          retention practices:
        </Text>
        <List type={"unordered"}>
          <List.Item>
            Account Creation Data: Retained for as long as your account is
            active. If you choose to delete your account, this information will
            be deleted.
          </List.Item>
          <List.Item>
            Content Data: Retained for as long as your account is active or
            until the content is deleted by you.
          </List.Item>
          <List.Item>
            Usage Data: Retained for no longer than 24 months.
          </List.Item>
        </List>

        <TextPageListItemHeader>Your Controls</TextPageListItemHeader>
        <Text>
          At Puzzlit, we want you to have control over your data. Here are some
          controls you have:
        </Text>
        <List type={"unordered"}>
          <List.Item>
            Account Deletion: You can delete your account at any time through
            the app settings. If you do so, your account and associated
            information will be deleted. However, information may not be deleted
            if we are legally required to preserve it.
          </List.Item>
          <List.Item>
            Communication Preferences: You can choose your notification
            preferences through the app settings.
          </List.Item>
        </List>

        <TextPageListItemHeader>Your Rights</TextPageListItemHeader>
        <Text>
          Your privacy is important, and you have certain rights over your data:
        </Text>
        <List type={"unordered"}>
          <List.Item>
            Access and Correction: You can request access to your data and
            correct it if it is inaccurate.
          </List.Item>
          <List.Item>
            Questions and Feedback: If you have any questions or feedback about
            our privacy practices, feel free to reach out to us.
          </List.Item>
        </List>
      </List>
      <Box>
        <Text>
          Contact: For any privacy-related inquiries, please contact our Privacy
          Support team at admin@puzzlitapp.com.
        </Text>
        <br />
        <Text>
          Thank you for using Puzzlit. We are committed to protecting your
          privacy and providing you with an enjoyable puzzle-solving experience.
          If you have any concerns or questions about your data and privacy,
          please do not hesitate to contact us.
        </Text>
        <br />
        <Text>Puzzlit LLC | admin@puzzlitapp.com</Text>
      </Box>
    </Stack>
  </TextPageWrapper>
);

export default PrivacyPolicyPage;
