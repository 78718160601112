import { Anchor, Text } from "@mantine/core";
import TextPageWrapper from "../../components/text_page/TextPageWrapper";

export const ContactPage = () => (
  <TextPageWrapper title={"Contact Puzzlit"}>
    <Text>
      You can contact us at{" "}
      <Anchor className={"text"} href={"mailto:admin@puzzlitapp.com"}>
        admin@puzzlitapp.com
      </Anchor>
    </Text>
  </TextPageWrapper>
);

export default ContactPage;
