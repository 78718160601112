import {
  Anchor,
  Group,
  AppShell as MantineAppShell,
  Stack,
  Title,
} from "@mantine/core";
import { PropsWithChildren } from "react";
import { Colors } from "../../styles/colors";
import { PageRoute } from "../../navigation/routes";
import { ReactComponent as TikTokLogo } from "./TikTok.svg";
import { ReactComponent as InstagramLogo } from "./InstagramLogo.svg";
import { ReactComponent as LinkedInLogo } from "./LinkedInLogo.svg";
import { useOs } from "@mantine/hooks";

import "./hover.css";

export const AppShell = ({ children }: PropsWithChildren) => {
  const os = useOs();
  const mobile = os === "ios" || os === "android";

  const AnchorText = ({ text, route }: { text: string; route: PageRoute }) => {
    return (
      <Anchor
        href={route}
        c={Colors.WHITE}
        underline={"never"}
        className={"text"}
      >
        <Title order={6}>{text}</Title>
      </Anchor>
    );
  };

  const AnchorLogo = ({
    href,
    children,
  }: { href: string } & PropsWithChildren) => {
    return (
      <Anchor
        href={href}
        c={Colors.WHITE}
        underline={"never"}
        target={"_blank"}
        className={"text"}
      >
        {children}
      </Anchor>
    );
  };

  return (
    <MantineAppShell p={"md"} h={"100vh"}>
      <MantineAppShell.Main pb={125}>{children}</MantineAppShell.Main>
      <MantineAppShell.Footer
        pt={5}
        pb={mobile ? 100 : 0}
        bg={Colors.GREY}
        h={mobile ? 95 : 85}
        style={{ borderTopWidth: 0 }}
      >
        <Stack align="center" justify="center" gap="sm">
          <Group justify="center" gap="sm">
            <AnchorLogo href={"https://www.tiktok.com/@puzzlitapp"}>
              <TikTokLogo />
            </AnchorLogo>
            <AnchorLogo href={"https://www.instagram.com/puzzlitapp"}>
              <InstagramLogo />
            </AnchorLogo>
            <AnchorLogo href={"https://www.linkedin.com/company/puzzlit"}>
              <LinkedInLogo />
            </AnchorLogo>
          </Group>
          <Group justify={"center"} gap={"sm"}>
            <AnchorText text={"HOME"} route={PageRoute.HOME} />
            <AnchorText text={"ABOUT"} route={PageRoute.ABOUT} />
            <AnchorText text={"CONTACT"} route={PageRoute.CONTACT} />
            <AnchorText text={"PRIVACY POLICY"} route={PageRoute.PRIVACY} />
            <AnchorText text={"STANDARDS"} route={PageRoute.STANDARDS} />
            <AnchorText text={"TERMS & CONDITIONS"} route={PageRoute.TERMS} />
          </Group>
        </Stack>
      </MantineAppShell.Footer>
    </MantineAppShell>
  );
};

export default AppShell;
