import { List, Stack, Text } from "@mantine/core";
import TextPageWrapper from "../../components/text_page/TextPageWrapper";
import TextPageListItemHeader from "../../components/text_page/TextPageListItemHeader";
import TextPageListItemSubHeader from "../../components/text_page/TextPageListItemSubHeader";

export const TermsAndConditionsPage = () => (
  <TextPageWrapper
    title={"Terms and Conditions for Puzzlit LLC"}
    lastUpdated={"June 24, 2024"}
  >
    <Stack>
      <List type={"ordered"}>
        <TextPageListItemHeader>Acceptance of Terms</TextPageListItemHeader>
        <Text mt={"sm"}>
          1.1. By choosing to access or use Puzzlit, you are indicating your
          acceptance and agreement to comply with these Terms and Conditions. It
          is essential to carefully read and understand the terms outlined
          below, as they govern your use of the Puzzlit app. If you do not agree
          with any part of these terms, you may not use the app.
        </Text>
        <TextPageListItemHeader>User Accounts</TextPageListItemHeader>
        <TextPageListItemSubHeader>2.1 Registration</TextPageListItemSubHeader>
        <Text>
          Account creation is not required but if done so, you agree to provide
          accurate and complete information during the registration process and
          to update such information to keep it accurate and current.
        </Text>
        <TextPageListItemSubHeader>
          2.2 Account Security
        </TextPageListItemSubHeader>
        <Text>
          You are responsible for maintaining the confidentiality of your
          account credentials and for all activities that occur under your
          account. Notify us immediately if you become aware of any unauthorized
          use of your account.
        </Text>
        <TextPageListItemHeader>User Content</TextPageListItemHeader>
        <TextPageListItemSubHeader>
          3.1 Posting Puzzles
        </TextPageListItemSubHeader>
        <Text>
          Puzzlit allows users to post completed puzzle scores. By posting these
          scores, you grant us a non-exclusive, worldwide, royalty-free license
          to use, modify, and display your score on our app.
        </Text>
        <TextPageListItemSubHeader>
          3.2 Content Restrictions
        </TextPageListItemSubHeader>
        <Text>
          You agree not to comment on any content that is harmful, threatening,
          abusive, harassing, defamatory, vulgar, obscene, invasive of another's
          privacy, or otherwise objectionable. Please refer to our privacy
          policy for any questions.
        </Text>
        <TextPageListItemHeader>
          Interaction with Other Users
        </TextPageListItemHeader>
        <TextPageListItemSubHeader>
          4.1 Comments and Likes
        </TextPageListItemSubHeader>
        <Text>
          Puzzlit allows users to comment on and like posted puzzles. Be
          respectful in your interactions. We reserve the right to remove any
          content or block users that violate these terms.
        </Text>
        <TextPageListItemHeader>
          Statistics and Analytics
        </TextPageListItemHeader>
        <Text>
          5.1 Puzzlit provides statistics on average scores and the number of
          puzzles played. These statistics are aggregated and anonymized. We may
          use this data for improving the app and for research purposes.
        </Text>
        <TextPageListItemHeader>Termination</TextPageListItemHeader>
        <Text>
          We reserve the right to suspend or terminate your account at our sole
          discretion if you violate these terms.
        </Text>
        <TextPageListItemHeader>Changes to Terms</TextPageListItemHeader>
        <Text>
          We may update these terms from time to time. You will be notified of
          significant changes. By continuing to use Puzzlit after any
          modifications, you agree to the updated terms.
        </Text>
        <TextPageListItemHeader>Contact Information</TextPageListItemHeader>
        <Text>
          If you have any questions about these terms, please contact us at:
          admin@puzzlitapp.com
        </Text>
      </List>
      <Text>
        By using Puzzlit, you acknowledge that you have read, understood, and
        agreed to these Terms and Conditions.
      </Text>
    </Stack>
  </TextPageWrapper>
);

export default TermsAndConditionsPage;
