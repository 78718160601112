import { MantineProvider } from "@mantine/core";
import NavigationWrapper from "./navigation/NavigationWrapper";
import { createUseStyles } from "react-jss";
import { Colors } from "./styles/colors";

const useStyles = createUseStyles(() => ({
  "@global": {
    body: {
      backgroundColor: Colors.GREY,
      color: Colors.WHITE,
    },
  },
}));

const App = () => {
  useStyles();

  return (
    <MantineProvider
      theme={{
        fontFamily: "GothamBold",
        headings: { fontFamily: "GothamUltra" },
        other: {
          meta: [
            { name: "apple-mobile-web-app-capable", content: "yes" },
            {
              name: "apple-mobile-web-app-status-bar-style",
              content: "black-translucent",
            },
            { name: "theme-color", content: Colors.GREY },
          ],
        },
      }}
    >
      <NavigationWrapper />
    </MantineProvider>
  );
};

export default App;
