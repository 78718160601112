import { Stack, Title, Text, List, Divider, Anchor } from "@mantine/core";
import { Colors } from "../../styles/colors";

export const AboutPage = () => (
  <Stack>
    <Title ta={"center"}>Who We Are</Title>
    <Text>
      We are a passionate team of puzzle lovers, tech enthusiasts, and young
      entrepreneurs dedicated to creating a unique and engaging puzzle platform.
      Our diverse backgrounds in app development, game design, and community
      building equip us with the skills and vision needed to bring Puzzlit to
      life.
    </Text>
    <Title>Our Team</Title>
    <Title order={4}>Matthew Hayden, Co-Founder, Business Development</Title>
    <Text>
      A puzzle enthusiast and entrepreneur with a background in industrial
      engineering and finance, graduated from Lehigh University in 2023. Matthew
      has always been at the forefront of the puzzle atmosphere fostering a
      passion for problem solving and even creating puzzles for his family from
      a young age.
    </Text>
    <Title order={4}>Evan Chen, Co-Founder, Business Development</Title>
    <Text>
      A puzzle enthusiast and entrepreneur with a background in industrial
      engineering and finance, graduated from Lehigh University in 2023.
    </Text>
    <Title order={4}>Christopher Banas, Co-Founder, Software Development</Title>
    <Text>
      A software engineer graduate from the Rochester Institute of Technology
      with a focus on frontend web & mobile development. As a lifelong student
      of technology, Chris enjoys learning about anything software related and
      world history.
    </Text>
    <Title order={4}>Patrick Curley, Co-Founder, Software Development</Title>
    <Text>
      A software engineer graduate from the Rochester Institute of Technology
      with a focus on game design.
    </Text>
    <Title order={4}>Jackson Catlett, Co-Founder, Branding</Title>
    <Text>
      Artist/Designer with a background in Product Design and a recent graduate
      from Lehigh University.
    </Text>
    <br />
    <Title>Credits</Title>
    <Text>
      We would like to extend our deepest gratitude to everyone who has
      supported and contributed to the Puzzlit effort. This journey would not
      have been possible without your invaluable help and encouragement.
    </Text>
    <Title order={4}>Beta Testers (Kickstarter Supporters)</Title>
    <List type={"unordered"}>
      <List.Item>Trevor Belinsky</List.Item>
      <List.Item>Tracie Catlett</List.Item>
      <List.Item>Colin McMahon</List.Item>
      <List.Item>Matthew Wyatt</List.Item>
      <List.Item>Lauren Hayden</List.Item>
      <List.Item>Anthony Labruna</List.Item>
      <List.Item>Anita Chen</List.Item>
      <List.Item>Justin Gelwicks</List.Item>
      <List.Item>Ben Hodson-Walker</List.Item>
      <List.Item>Mark Catlett</List.Item>
      <List.Item>Will Catlett</List.Item>
      <List.Item>Eric Osterman</List.Item>
      <List.Item>Ethan Kozden</List.Item>
      <List.Item>Graham Schwartz</List.Item>
      <List.Item>Emily B</List.Item>
      <List.Item>Everette Taylor</List.Item>
      <List.Item>George Hayden</List.Item>
      <List.Item>Karen Hayden</List.Item>
      <List.Item>Stazja Stuccio</List.Item>
    </List>
    <Title order={4}>Special Thanks</Title>
    <List type={"unordered"}>
      <List.Item>Joseph Wesselman: Development feedback</List.Item>
      <List.Item>Owen Fitzgerald: Puzzle support</List.Item>
    </List>
    <Title order={4}>Family and Friends</Title>
    <Text>
      A heartfelt thank you to our families and friends who believed in us and
      provided unwavering support throughout this journey.
    </Text>
    <Title order={4}>Community</Title>
    <Text>
      To our incredible community of puzzle enthusiasts, your passion and
      feedback have been the driving force behind Puzzlit. Thank you for being a
      part of this journey with us.
    </Text>

    <Title order={4}>Tabler</Title>

    <Text>
      Thank you to{" "}
      <Anchor
        href={"https://tabler.io"}
        target={"_blank"}
        c={Colors.PURPLE}
        underline={"hover"}
        className={"text"}
      >
        Tabler
      </Anchor>{" "}
      for following us to use their open source icon library within this site
      and our app.
    </Text>
    <br />
    <Divider />
    <Text fs="italic" c="dimmed">
      This is a living document. We will continue to update it to acknowledge
      everyone who has played a role in our ongoing journey.
    </Text>
  </Stack>
);

export default AboutPage;
