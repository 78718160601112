import { Stack, Text, Title } from "@mantine/core";
import { PropsWithChildren } from "react";

export type TextPageWrapperProps = {
  title: string;
  lastUpdated?: string;
} & PropsWithChildren;

export const TextPageWrapper = ({
  title,
  lastUpdated,
  children,
}: TextPageWrapperProps) => (
  <Stack gap={"lg"}>
    <Stack align={"center"} gap={"xs"}>
      <Title order={1} ta={"center"}>
        {title}
      </Title>
      {lastUpdated && <Text ta={"center"}>Last updated: {lastUpdated}</Text>}
    </Stack>
    {children}
  </Stack>
);

export default TextPageWrapper;
