import { Anchor, Button, Group, Text } from "@mantine/core";
import { ReactComponent as AppleIcon } from "./AppleLogo.svg";
import { Colors } from "../../styles/colors";
import { useHover } from "@mantine/hooks";

export const DownloadAppButton = () => {
  const { hovered, ref } = useHover();

  return (
    <div ref={ref}>
      <Anchor
        target={"_blank"}
        href={
          "https://apps.apple.com/us/app/puzzlit-puzzles-made-social/id6472233094"
        }
      >
        <Button
          size={"xl"}
          bg={hovered ? Colors.PURPLE : Colors.WHITE}
          className={"button"}
        >
          <Group gap={"sm"}>
            <AppleIcon fill={hovered ? Colors.WHITE : Colors.GREY} />
            <Text c={hovered ? Colors.WHITE : Colors.GREY}>
              Download the app
            </Text>
          </Group>
        </Button>
      </Anchor>
    </div>
  );
};

export default DownloadAppButton;
